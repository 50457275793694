// global styles

a {
  cursor: pointer;
}

.app-container {
  min-height: 320px;
}

.admin-nav {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #e8e9ea;
  border-bottom: 1px solid #ccc;
}

.btn-delete-account {
  width: 40px;
  text-align: center;
  box-sizing: content-box;
}

html,
body {
  height: 100%;
}

@font-face {
  font-family: "Karla";
  src: url(./assets/fonts/Karla/Karla-VariableFont_wght.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: Roboto, "Karla", sans-serif;
}

@color-blue: #055D9D;

.btn-primary {
  background: @color-blue  !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.form-control {
  height: 3em;
}

.cursor-pointer {
  cursor: pointer;
}
